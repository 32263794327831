import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

const Header = props => {
  const [skill, setSkill] = useState('variety of skills');
  let [counter, setCounter] = useState(0);
  const [headerIcon, setHeaderIcon] = useState('icon fa-laptop')
  const [opacity, setOpacity] = useState(100);
  const skills = ['Scan to BIM', 'Javascript', 'CAD Drafting', 'HTML & CSS', 'Measured Building Survey', 'React JS',
                  'Revit Modeling', 'React Native', 'Laser Scanning', 'C#' , '3D Visualization'];

  const swapItem = useCallback(() => {
    setSkill(skills[counter]);
    setOpacity(100);
    (counter % 2 === 0) ? setHeaderIcon('icon fa-cube') : setHeaderIcon('icon fa-code');
    (counter < skills.length - 1) ? setCounter(counter++) : setCounter(counter = 0);
    setTimeout(setOpacity, 2000, 0);
  },[]);

  useEffect(() => {
    setTimeout(setOpacity, 2000, 0);
    setInterval(() => {
      swapItem();
    }, 3000);
  },[swapItem, setOpacity]);

  return (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className={headerIcon} style={{opacity: opacity}}></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Privo Digital</h1>
        <p style={{opacity: opacity}}>{skill}</p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('cad')
            }}
          >
            CAD
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('code')
            }}
          >
            Code
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)};

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
