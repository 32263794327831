import PropTypes from 'prop-types'
import React from 'react'
import picLP from '../images/LP_portrait.png'
import picATC from '../images/ATC.jpg'
import picNqb from '../images/Naviqb.jpg'
import picPM from '../images/PrimaryMaths.jpg'
import picGL from '../images/locator.jpg'
import picGherkin from '../images/GherkinLP-600x600.jpg'
import picPlaceHolder from '../images/placeholder-600x600.png'
import picWebAR from '../images/WebAR_placeholder.png'
class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image portrait">
            <img src={picLP} alt="" />
          </span>
          <p>
          Hello,<br /><br />
          I'm Laszlo<br /><br />
          Over the years I have gained valuable skills and experience in different 
          areas of professional life.<br /><br />
          I have spent most of the last decade in engineering surveying, working 
          in Measured Building Survey and Scan to BIM, which gave me the opportunity 
          to master the necessary skills both on-site and in the office.<br /><br />
          During the past few years, however, I have been pursuing my hobby in a professional
          environment at a modern creative studio as a software developer, specialising 
          in developing complex applications in modern interactive coding languages with a 
          focus on TypeScript, JavaScript, React, React Native, HTML5, CSS, C#.<br /><br />
          I am a highly motivated professional driven by a “keep learning” attitude 
          and it is because of this that I am always looking for new challenges 
          in life.
          </p>
          {close}
        </article>

        <article
          id="cad"
          className={`${this.props.article === 'cad' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">CAD</h2>
          <span className="image main">
            <img src={picGherkin} alt="Gherkin" />
          </span>
          <p>
            While I am collecting some of my project to populate this page, here 
            is a 3D render I created several years ago.
          </p>
          <span className="image main">
            <img src={picPlaceHolder} alt="placeholder" />
          </span>
          {close}
        </article>
        
        <article
          id="code"
          className={`${this.props.article === 'code' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Code</h2>
          <span className="image main">
            <a href="https://atc.privo.uk">
              <img src={picATC} alt="ATC" />
            </a>
          </span>
          <p>
            Air Traffic Control Simulator. My first real venture into software development. 
            Turning two dreams into reality with one project: start coding and controlling air 
            traffic (okay, only alternative reality in the latter case, I guess...)
          </p>
          <span className="image main">
            <a href="https://naviqb.privo.uk">
              <img src={picNqb} alt="Naviqb" />
            </a>
          </span>
          <p>
            Navi-qb - Our poor "qb" is lost and needs help finding the way to 
            his destinations...<br />
            An introductory educational algorithm game for kids (or anyone) to 
            help develop their programming thinking by combining and repeating 
            simple steps to solve complex problems.<br />
            Inspired by the brilliant Primo toy: 'Cubetto'.
          </p>
          <span className="image main">
            <a href="https://primarymaths.privo.uk">
              <img src={picPM} alt="Primary Maths" />
            </a>
          </span>
          <p>
            Primary Maths - speaks for itself really. What else is there to do during a 
            pandemic lockdown other than building something useful for your primary 
            shool age child to improve their maths skills? Also a great way to get 
            better at coding - two pigeons with one stone, right?
          </p>
          <span className="image main">
            <a href="https://locator.privo.uk">
              <img src={picGL} alt="Google locator" />
            </a>
          </span>
          <p>
            Google Locator - a very simple exercise in React to make use of the 
            Google Map API service. Honestly, it doesn't do much, it just tells 
            you where you are - should you wonder...
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" required/>
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" required/>
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" required></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://linkedin.com/in/lprivo"
                className="icon fa-linkedin"
              >
                <span className="label">Linkedin</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/lprivo"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
